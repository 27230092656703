import React from 'react';
import {
  imgLogo
} from '../../assets/images/img'; // Adjust the path as necessary

const Footer = () => {
  return (
    <footer className="text-white py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-4">
            <img src={imgLogo} alt="FK Soluciones Automotrices" className="mb-2" style={{ width: '150px' }} />
            <p>En FK Soluciones Automotrices, brindamos servicios automotrices de alta calidad directamente en tu hogar o lugar de trabajo.</p>
            <p>© 2024 FK Soluciones Automotrices</p>
          </div>
          <div className="col-md-2 mb-4">
            <h5 className="text-uppercase">Empresa</h5>
            <ul className="list-unstyled">
              <li><a href="#inicio" className="text-white">Inicio</a></li>
              <li><a href="#nosotros" className="text-white">Nosotros</a></li>
              <li><a href="#contacto" className="text-white">Contacto</a></li>
            </ul>
          </div>
          <div className="col-md-2 mb-4">
            <h5 className="text-uppercase">Servicios</h5>
            <ul className="list-unstyled">
              <li><a href="#servicios" className="text-white">Diagnóstico Eléctrico</a></li>
              <li><a href="#servicios" className="text-white">Reparación de Sistemas Eléctricos</a></li>
              <li><a href="#servicios" className="text-white">Mantenimiento Preventivo</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;