import React from 'react';

const Dashboard = () => {
  return (
    <main className="container my-5">
      <section>
        <h3>Últimas Órdenes de Trabajo</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Cliente</th>
              <th>Servicio</th>
              <th>Fecha</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Juan Pérez</td>
              <td>Reparación Eléctrica</td>
              <td>01/08/2024</td>
              <td><span className="badge bg-success">Completado</span></td>
            </tr>
            <tr>
              <td>Maria González</td>
              <td>Mantenimiento Preventivo</td>
              <td>03/08/2024</td>
              <td><span className="badge bg-warning">En Proceso</span></td>
            </tr>
            <tr>
              <td>Carlos Sánchez</td>
              <td>Programación de Llaves</td>
              <td>05/08/2024</td>
              <td><span className="badge bg-danger">Pendiente</span></td>
            </tr>
          </tbody>
        </table>
      </section>

      {/* Resumen de Estadísticas */}
      <section className="row text-center mb-4">
        <div className="col-md-3 mb-4">
          <div className="card bg-primary text-white">
            <div className="card-body">
              <h5 className="card-title">Órdenes Completadas</h5>
              <p className="card-text display-4">120</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="card bg-success text-white">
            <div className="card-body">
              <h5 className="card-title">Ingresos del Mes</h5>
              <p className="card-text display-4">$25,000</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="card bg-warning text-white">
            <div className="card-body">
              <h5 className="card-title">Inventario Crítico</h5>
              <p className="card-text display-4">5 items</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="card bg-danger text-white">
            <div className="card-body">
              <h5 className="card-title">Alertas</h5>
              <p className="card-text display-4">3</p>
            </div>
          </div>
        </div>
      </section>

      {/* Gráficos y Órdenes Recientes */}
      <section className="row">
        <div className="col-md-6 mb-4">
          <div className="card">
            <div className="card-header bg-gradient-primary text-white">
              Ingresos por Mes
            </div>
            <div className="card-body">
              <canvas id="revenueChart"></canvas>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card">
            <div className="card-header bg-gradient-primary text-white">
              Órdenes de Trabajo Recientes
            </div>
            <div className="card-body">
              <ul className="list-group">
                <li className="list-group-item">Orden #1543 - Reparación Eléctrica</li>
                <li className="list-group-item">Orden #1542 - Escaneo de Vehículo</li>
                <li className="list-group-item">Orden #1541 - Programación de Llaves</li>
                <li className="list-group-item">Orden #1540 - Reparación de Radio</li>
                <li className="list-group-item">Orden #1539 - Mantenimiento General</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Notificaciones Importantes */}
      <section className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header bg-gradient-primary text-white">
              Notificaciones Importantes
            </div>
            <div className="card-body">
              <ul className="list-group">
                <li className="list-group-item list-group-item-danger">Inventario bajo en cables eléctricos.</li>
                <li className="list-group-item list-group-item-warning">Orden de trabajo #1543 está pendiente de revisión.</li>
                <li className="list-group-item list-group-item-success">Nuevo taller asociado registrado: Taller 3.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="card text-white bg-primary mb-3">
              <div className="card-body">
                <h5 className="card-title">Órdenes de Trabajo</h5>
                <p className="card-text">Total: 125</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card text-white bg-success mb-3">
              <div className="card-body">
                <h5 className="card-title">Ingresos</h5>
                <p className="card-text">$150,000</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card text-white bg-warning mb-3">
              <div className="card-body">
                <h5 className="card-title">Inventario Crítico</h5>
                <p className="card-text">5 Productos</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card text-white bg-danger mb-3">
              <div className="card-body">
                <h5 className="card-title">Clientes Nuevos</h5>
                <p className="card-text">20 Este Mes</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Dashboard;