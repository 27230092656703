import React, { useState } from 'react';
import axios from 'axios';

const CategoryForms = () => {
    const [categoryName, setCategoryName] = useState('');
    const [editCategoryName, setEditCategoryName] = useState('');
    const [editCategoryId, setEditCategoryId] = useState(null);

    const handleAddCategory = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost/fk-soluciones-automotrices/backend/inventario/categoria/add_categoria.php', {
                nombre_cat: categoryName
            });
            alert(response.data.message || response.data.error);
            setCategoryName('');
            // Optionally, you can refresh the category list here
            document.getElementById('addCategoryModal').classList.remove('show');
            document.body.classList.remove('modal-open');
            document.querySelector('.modal-backdrop').remove();
        } catch (error) {
            console.error('Error adding category:', error);
        }
    };

    const handleEditCategory = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put('http://localhost/fk-soluciones-automotrices/backend/inventario/categoria/edit_categoria.php', {
                id_cat: editCategoryId,
                nombre_cat: editCategoryName
            });
            alert(response.data.message || response.data.error);
            setEditCategoryName('');
            setEditCategoryId(null);
            // Optionally, you can refresh the category list here
            document.getElementById('editCategoryModal').classList.remove('show');
            document.body.classList.remove('modal-open');
            document.querySelector('.modal-backdrop').remove();
        } catch (error) {
            console.error('Error editing category:', error);
        }
    };

    return (
        <>
            {/* Modal para Añadir Categoría */}
            <div className="modal fade" id="addCategoryModal" tabIndex="-1" aria-labelledby="addCategoryModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addCategoryModalLabel">Añadir Nueva Categoría</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form id="categoryForm" onSubmit={handleAddCategory} noValidate>
                                <div className="mb-3">
                                    <label htmlFor="categoryName" className="form-label">Nombre de la Categoría</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="categoryName" 
                                        value={categoryName}
                                        onChange={(e) => setCategoryName(e.target.value)}
                                        required 
                                    />
                                    <div className="invalid-feedback">Por favor, ingrese el nombre de la categoría.</div>
                                </div>
                                <button type="submit" className="btn btn-primary">Guardar Categoría</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal para Editar Categoría */}
            <div className="modal fade" id="editCategoryModal" tabIndex="-1" aria-labelledby="editCategoryModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editCategoryModalLabel">Editar Categoría</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form id="editCategoryForm" onSubmit={handleEditCategory} noValidate>
                                <input type="hidden" id="editCategoryId" value={editCategoryId} />
                                <div className="mb-3">
                                    <label htmlFor="editCategoryName" className="form-label">Nombre de la Categoría</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="editCategoryName" 
                                        value={editCategoryName}
                                        onChange={(e) => setEditCategoryName(e.target.value)}
                                        required 
                                    />
                                    <div className="invalid-feedback">Por favor, ingrese el nombre de la categoría.</div>
                                </div>
                                <button type="submit" className="btn btn-primary">Guardar Cambios</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CategoryForms;
