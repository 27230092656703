// src/layouts/AdminLayout.js
import React from 'react';
import HeaderAdmin from '../pages/admin/HeaderAdmin'; // Ajustado para la ubicación correcta
import FooterAdmin from '../pages/admin/FooterAdmin'; // Ajustado para la ubicación correcta

const AdminLayout = ({ children }) => (
  <div>
    <HeaderAdmin />
    {children}
    <FooterAdmin />
  </div>
);

export default AdminLayout;