import React from 'react';
import '../css/style.css';
import { imgLogo } from '../../assets/images/img';
import useStickyHeader from '../../components/useStickyHeader'; // Llamada Funcion sticky 

const Header = () => {
  useStickyHeader(); // Funcion sticky

  return (
    // Header que contiene el navbar
    <header>
      <nav id="navbar" className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand d-flex align-items-center" href="/">
            <img src={imgLogo} className="logo rounded-circle me-2" alt="Logo" />
          </a>
          <a className="nav-link active" aria-current="page" href="#inicio" style={{ color: 'white' }}>
            FK Soluciones Automotrices
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#inicio">Inicio</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#nosotros">Nosotros</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#servicios">Nuestros Servicios</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contacto">Contacto</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Agendar">Agendar</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;