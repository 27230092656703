import React from 'react';
import {
    imgInicio,
    imgMecConPers,
    imgVehUbi,
    imgContact
} from '../../assets/images/img'; // Ajusta la ruta según sea necesario

const Inicio = () => {
    return (
        <main>
            {/* Sección de inicio, introducción general */}
            <section id="inicio" className="text-center">
                <div className="container-fluid my-5">
                    <div className="row align-items-center header-section">
                        <div className="col-md-6">
                            <img src={imgInicio} alt="Imagen del taller" className="profile-img" />
                        </div>
                        <div className="col-md-6">
                            <h1>FK Soluciones Automotrices</h1>
                            <p>Bienvenidos a mi taller mecánico eléctrico. Soy un experto en la reparación y mantenimiento
                                de vehículos, con especialización en sistemas eléctricos. Ofrezco servicios de alta calidad
                                y soluciones para sus vehículos.</p>
                            <a href="https://wa.me/56984056256" className="whatsapp-float" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-whatsapp my-float"></i>
                            </a>
                            <a href="https://wa.me/56984056256" className="btn-wsp" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-whatsapp"></i> Contáctame
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Sección sobre nosotros, descripción del mecánico y sus cualidades */}
            <section id="nosotros" className="py-5 rounded-5">
                <div className="container my-5">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Sobre Mí</h2>
                            <div className="row mb-3">
                                <div className="col-2">
                                    <div className="feature-number">1</div>
                                </div>
                                <div className="col-10">
                                    <h4>Experiencia</h4>
                                    <p>17 años de experiencia en electricidad automotriz, con título técnico en Electricidad Electrónica y Automotriz.</p>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-2">
                                    <div className="feature-number">2</div>
                                </div>
                                <div className="col-10">
                                    <h4>Pasión</h4>
                                    <p>Tengo una pasión por la electricidad automotriz y la resolución de problemas.</p>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-2">
                                    <div className="feature-number">3</div>
                                </div>
                                <div className="col-10">
                                    <h4>Compromiso</h4>
                                    <p>Estoy comprometido con la satisfacción del cliente y la entrega de servicios de alta calidad.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">
                                    <div className="feature-number">4</div>
                                </div>
                                <div className="col-10">
                                    <h4>Actualización</h4>
                                    <p>Me mantengo actualizado con las últimas tecnologías y tendencias de la industria.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={imgVehUbi} alt="Imagen del mecánico" className="img-fluid rounded" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Sección de servicios, detalles de los servicios ofrecidos */}
            <section id="servicios" className="py-5 mb-5">
                <div className="container my-5">
                    <h2 className="text-center mb-5">Trabajos que Realizo</h2>

                    {/* Primera fila de servicios */}
                    <div className="row text-center mb-4">
                        <div className="col-md-4">
                            <div className="service-box">
                                <i className="fas fa-key service-icon" style={{ fontSize: '2.5rem', color: '#ff6b6b' }}></i>
                                <h4>Llaves</h4>
                                <p>Fabricación, programación y reparación de llaves para todo tipo de vehículos.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <i className="fas fa-laptop-medical service-icon" style={{ fontSize: '2.5rem', color: '#4ecdc4' }}></i>
                                <h4>Scaner</h4>
                                <p>Diagnóstico avanzado con escáner para identificar y solucionar problemas técnicos.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <i className="fas fa-cogs service-icon" style={{ fontSize: '2.5rem', color: '#f7b733' }}></i>
                                <h4>Programaciones</h4>
                                <p>Programación de unidades electrónicas y sistemas de control en vehículos.</p>
                            </div>
                        </div>
                    </div>

                    {/* Segunda fila de servicios */}
                    <div className="row text-center">
                        <div className="col-md-4">
                            <div className="service-box">
                                <i className="fas fa-car-crash service-icon" style={{ fontSize: '2.5rem', color: '#1e90ff' }}></i>
                                <h4>Reparaciones después de Siniestros</h4>
                                <p>Restauración de sistemas eléctricos dañados tras un accidente.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <i className="fas fa-shield-alt service-icon" style={{ fontSize: '2.5rem', color: '#2ed573' }}></i>
                                <h4>Trabajos de Seguridad</h4>
                                <p>Instalación de GPS, corta corriente, y otros sistemas de seguridad.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <i className="fas fa-bolt service-icon" style={{ fontSize: '2.5rem', color: '#ff4757' }}></i>
                                <h4>Reparaciones Eléctricas</h4>
                                <p>Reparación y mantenimiento de sistemas eléctricos en todo tipo de vehículos.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Sección de testimonios de clientes */}
            <section>
                <div className="container my-5">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={imgMecConPers} alt="Cliente satisfecho" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-6">
                            <h2>Testimonios de Clientes</h2>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="testimonial-box p-3">
                                        <h4>Juan Pérez</h4>
                                        <p>Excelente servicio y atención. ¡Mi vehículo funciona como nuevo!</p>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="testimonial-box p-3">
                                        <h4>Ana García</h4>
                                        <p>Resolvió el problema eléctrico que tenía de forma rápida y eficiente.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="testimonial-box p-3">
                                        <h4>Pedro Rodríguez</h4>
                                        <p>Un mecánico confiable y honesto. Recomiendo sus servicios sin dudarlo.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Sección de agendamiento de citas, incluyendo un calendario */}
            <section className="agenda-section">
                <div className="container my-5">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Agenda una Cita</h2>
                            <div className="timeline">
                                <div className="timeline-step">
                                    <div className="step-icon">
                                        <i className="bi bi-telephone-fill"></i>
                                    </div>
                                    <div className="step-details">
                                        <h4>Contacto Inicial</h4>
                                        <p>Contáctame por teléfono o correo electrónico para describir tu problema.</p>
                                    </div>
                                </div>
                                <div className="timeline-step">
                                    <div className="step-icon">
                                        <i className="bi bi-calendar-event-fill"></i>
                                    </div>
                                    <div className="step-details">
                                        <h4>Programación</h4>
                                        <p>Te brindo opciones de horarios disponibles para una cita.</p>
                                    </div>
                                </div>
                                <div className="timeline-step">
                                    <div className="step-icon">
                                        <i className="bi bi-check-circle-fill"></i>
                                    </div>
                                    <div className="step-details">
                                        <h4>Confirmación</h4>
                                        <p>Confirma tu cita y prepárate para llevar tu vehículo al taller.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h4>Servicios a Domicilio</h4>
                            <p>Ofrecemos <strong>servicios a domicilio</strong>, desplazándonos en nuestra <strong>camioneta equipada</strong> para realizar diagnósticos, reparaciones y mantenimiento en el lugar que prefieras. Con nosotros, tu vehículo recibe el mejor cuidado sin que tengas que moverte.</p>
                            <div className="button-container mt-3">
                                <a href="/agendar" className="btn btn-primary btn-custom">Agendar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contacto" className="py-5">
                <div className="container text-center">
                    <h2>¿Necesitas ayuda?</h2>
                    <p>¡Estamos aquí para asistirte! Contáctanos a través de WhatsApp para obtener una respuesta rápida.</p>
                    <a href="https://wa.me/56984056256" className="btn-wsp" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-whatsapp"></i> Contáctame
                    </a>
                </div>
            </section>

            {/* Sección de contacto, incluyendo detalles de contacto y una imagen */}
            <section id="contacto">
                <div className="container my-5">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Contáctame</h2>
                            <div className="contact-info">
                                <div className="mb-4 d-flex align-items-center">
                                    <i className="bi bi-telephone-fill me-3 contact-icon"></i>
                                    <div>
                                        <h4>Teléfono</h4>
                                        <p>+56 9 84056256</p>
                                    </div>
                                </div>
                                <div className="mb-4 d-flex align-items-center">
                                    <i className="bi bi-envelope-fill me-3 contact-icon"></i>
                                    <div>
                                        <h4>Correo Electrónico</h4>
                                        <p>fktodoauto@gmail.com</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <i className="bi bi-geo-alt-fill me-3 contact-icon"></i>
                                    <div>
                                        <h4>Oficina</h4>
                                        <p>Santiago, Morande 835, piso 5, oficina 518</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={imgContact} alt="Imagen de contacto" className="img-fluid rounded" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Sección de ubicación y horarios */}
            <section>
                <div className="container my-5">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={imgVehUbi} alt="Mapa de ubicación" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-6">
                            <h2>Ubicación y Horarios</h2>
                            <div className="row">
                                <div className="col-2">
                                    <div className="step-number">1</div>
                                </div>
                                <div className="col-10 mb-4">
                                    <h4>Ubicación</h4>
                                    <p>Ofrecemos servicios a domicilio, llegando directamente a la ubicación que prefieras dentro de Santiago.</p>
                                </div>
                                <div className="col-2">
                                    <div className="step-number">2</div>
                                </div>
                                <div className="col-10 mb-4">
                                    <h4>Horarios</h4>
                                    <p>De lunes a viernes de 9:00 AM a 19:00 PM. Sábado de 9:00 AM a 13:00 PM.</p>
                                </div>
                                <div className="col-2">
                                    <div className="step-number">3</div>
                                </div>
                                <div className="col-10">
                                    <h4>Garantía</h4>
                                    <p>Todos los trabajos son garantizados por 6 meses.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="faq" className="py-5">
                <div className="container">
                    <h2 className="text-center mb-4">Preguntas Frecuentes</h2>
                    <div className="accordion" id="accordionFAQ">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    ¿Qué servicios ofrecen?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#accordionFAQ">
                                <div className="accordion-body">
                                    Ofrecemos servicios de diagnóstico eléctrico, reparación de sistemas eléctricos, y mantenimiento preventivo a domicilio.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    ¿Cómo puedo agendar una cita?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionFAQ">
                                <div className="accordion-body">
                                    Puedes agendar una cita contactándonos por teléfono, correo electrónico o a través de nuestro formulario en línea. También puedes utilizar nuestro servicio de WhatsApp.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    ¿Cuáles son los costos de los servicios?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionFAQ">
                                <div className="accordion-body">
                                    Los costos varían dependiendo del servicio requerido. Te proporcionaremos un presupuesto detallado después de realizar un diagnóstico inicial.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    ¿Qué áreas cubren sus servicios a domicilio?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                                data-bs-parent="#accordionFAQ">
                                <div className="accordion-body">
                                    Cubrimos una amplia área de Santiago. Si estás fuera de nuestra área de servicio, por favor contáctanos para discutir opciones.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    ¿Qué sucede si necesito un servicio que no está listado?
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                                data-bs-parent="#accordionFAQ">
                                <div className="accordion-body">
                                    Si necesitas un servicio que no está listado, por favor contáctanos. Estaremos encantados de discutir tus necesidades y ver cómo podemos ayudarte.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Inicio;