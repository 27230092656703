import React from 'react';
import useFormHandler from '../../components/agendarFuncion.js'; // LLamar a la funcion
import '../css/style_agenda.css';

const Agendar = () => {
  const { formRef, mensajeEnviadoRef, formValid } = useFormHandler();

  return (
    <div>
      <section>
        <a href="/Login">Login</a>
        <a href="/Dashboard">Sistema</a>
        <a href="https://wa.me/56984056256" className="whatsapp-float" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-whatsapp my-float"></i>
        </a>
        <div className="container form-container">
          <form className="needs-validation" noValidate ref={formRef}>
            <div className="form-header">Datos personales y del vehículo</div>
            <div className="form-section">
              <div className="row">
                <div className="col-md-6">
                  <h5>Datos personales</h5>
                  <div className="form-group">
                    <label htmlFor="nombre">Nombre</label>
                    <input type="text" className="form-control" id="nombre" placeholder="Nombre"
                      required pattern="[A-Za-z\s]+"
                      title="El nombre solo debe contener letras y espacios." />
                    <div className="invalid-feedback">
                      Por favor, ingresa un nombre válido.
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="apellido">Apellido</label>
                    <input type="text" className="form-control" id="apellido" placeholder="Apellido"
                      required pattern="[A-Za-z\s]+"
                      title="El apellido solo debe contener letras y espacios." />
                    <div className="invalid-feedback">
                      Por favor, ingresa un apellido válido.
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="correo">Correo</label>
                    <input type="email" className="form-control" id="correo" placeholder="Correo"
                      required />
                    <div className="invalid-feedback">
                      Por favor, ingresa un correo válido.
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="rut">Rut</label>
                    <input type="text" className="form-control" id="rut" placeholder="Rut" required
                      pattern="\d{7,8}-[\dKk]{1}"
                      title="El formato del RUT debe ser 12345678-9 o 12345678-K." />
                    <div className="invalid-feedback">
                      Por favor, ingresa un RUT válido.
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="telefono">Teléfono</label>
                    <input type="tel" className="form-control" id="telefono"
                      placeholder="Teléfono Ej: +569XXXXXXXX" required pattern="^\+569\d{8}$"
                      title="El formato del teléfono debe ser +569XXXXXXXX." />
                    <div className="invalid-feedback">
                      Por favor, ingresa un teléfono válido.
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <h5>Datos del vehículo</h5>
                  <div className="form-group">
                    <label htmlFor="patente">Patente</label>
                    <input type="text" className="form-control" id="patente"
                      placeholder="Patente Ej: ABC123 o AB-1234" required
                      pattern="^[A-Z]{3}[0-9]{3}$|^[A-Z]{2}-[0-9]{4}$"
                      title="El formato de la patente debe ser ABC123 o AB-1234, usando letras mayúsculas y números." />
                    <div className="invalid-feedback">
                      Por favor, ingresa una patente válida.
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="marca">Marca</label>
                    <input type="text" className="form-control" id="marca" placeholder="Marca" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="modelo">Modelo</label>
                    <input type="text" className="form-control" id="modelo" placeholder="Modelo" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="anio">Año</label>
                    <select className="form-control" id="anio" required>
                      <option value="">Seleccione...</option>
                      {[...Array(24)].map((_, i) => (
                        <option key={2024 - i} value={2024 - i}>{2024 - i}</option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      Por favor, selecciona un año.
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="combustible">Tipo de Combustible</label>
                    <select className="form-control" id="combustible" required>
                      <option value="">Seleccione...</option>
                      <option value="Bencina">Bencina</option>
                      <option value="Diesel">Diesel</option>
                    </select>
                    <div className="invalid-feedback">
                      Por favor, selecciona un tipo de combustible.
                    </div>
                  </div>
                </div>
                <div className="form-group mt-4">
                  <label htmlFor="comentarios">Comentarios</label>
                  <textarea className="form-control" id="comentarios" rows="4"
                    placeholder="Describe la situación o los trabajos requeridos (ej. llaves, scanner, etc.)"
                    style={{ resize: 'none' }}></textarea>
                  <small className="text-muted">Proporcione detalles adicionales o solicitudes
                    específicas.</small>
                </div>
              </div>
            </div>
            <div className="form-group d-flex justify-content-between">
              <a href="/html/index.html" className="btn btn-secondary">Volver</a>
              <button type="submit" className="btn btn-primary">Enviar</button>
            </div>
          </form>
          <div id="mensaje-enviado" style={{ display: 'none' }} ref={mensajeEnviadoRef}>
            <p>¡Datos enviados exitosamente!</p>
          </div>
        </div>
      </section>

      <section id="contacto" className="py-5">
        <div className="container text-center">
          <h2>¿Necesitas ayuda?</h2>
          <p>¡Estamos aquí para asistirte! Contáctanos a través de WhatsApp para obtener una respuesta rápida.</p>
          <a href="https://wa.me/56984056256" className="btn-wsp" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-whatsapp"></i> Contáctame
          </a>
        </div>
      </section>

      <section id="faq" className="py-5">
        <div className="container">
          <h2 className="text-center mb-4">Preguntas Frecuentes</h2>
          <div className="accordion" id="accordionFAQ">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  ¿Qué servicios ofrecen?
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                data-bs-parent="#accordionFAQ">
                <div className="accordion-body">
                  Ofrecemos servicios de diagnóstico eléctrico, reparación de sistemas eléctricos, y mantenimiento preventivo a domicilio.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  ¿Cómo puedo agendar una cita?
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                data-bs-parent="#accordionFAQ">
                <div className="accordion-body">
                  Puedes agendar una cita contactándonos por teléfono, correo electrónico o a través de nuestro formulario en línea. También puedes utilizar nuestro servicio de WhatsApp.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  ¿Cuáles son los costos de los servicios?
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                data-bs-parent="#accordionFAQ">
                <div className="accordion-body">
                  Los costos varían dependiendo del servicio requerido. Te proporcionaremos un presupuesto detallado después de realizar un diagnóstico inicial.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  ¿Qué áreas cubren sus servicios a domicilio?
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                data-bs-parent="#accordionFAQ">
                <div className="accordion-body">
                  Cubrimos una amplia área de Santiago. Si estás fuera de nuestra área de servicio, por favor contáctanos para discutir opciones.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  ¿Qué sucede si necesito un servicio que no está listado?
                </button>
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                data-bs-parent="#accordionFAQ">
                <div className="accordion-body">
                  Si necesitas un servicio que no está listado, por favor contáctanos. Estaremos encantados de discutir tus necesidades y ver cómo podemos ayudarte.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Agendar;