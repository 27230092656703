import { useEffect, useRef, useState } from 'react';

const useFormHandler = () => {
  const formRef = useRef(null);
  const mensajeEnviadoRef = useRef(null);
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    const handleInputValidation = (event) => {
      const input = event.target;
      if (input.checkValidity()) {
        input.classList.add('is-valid');
        input.classList.remove('is-invalid');
      } else {
        input.classList.add('is-invalid');
        input.classList.remove('is-valid');
      }
    };

    const handleSubmit = (event) => {
      event.preventDefault(); // Evitar el envío tradicional

      if (formRef.current.checkValidity()) {
        setFormValid(true); // Actualizar el estado de validez

        if (mensajeEnviadoRef.current) {
          mensajeEnviadoRef.current.style.display = 'block';
        }

        formRef.current.querySelectorAll('.is-valid, .is-invalid').forEach(input => {
          input.classList.remove('is-valid', 'is-invalid');
        });

        formRef.current.reset();
      } else {
        formRef.current.classList.add('was-validated');
      }
    };

    if (formRef.current) {
      const inputs = formRef.current.querySelectorAll('input, select');
      inputs.forEach(input => input.addEventListener('input', handleInputValidation));
      formRef.current.addEventListener('submit', handleSubmit);

      return () => {
        inputs.forEach(input => input.removeEventListener('input', handleInputValidation));
        formRef.current.removeEventListener('submit', handleSubmit);
      };
    }
  }, []);

  return { formRef, mensajeEnviadoRef, formValid };
};

export default useFormHandler;