import React, { useState, useEffect } from 'react';

const Agenda = () => {
    const [appointments, setAppointments] = useState([
        { id: 1, client: 'Juan Pérez', technician: 'Tecnico 1', date: '2024-09-05T10:00', status: 'pendiente' }
        // Agrega más citas aquí
    ]);

    const [filteredAppointments, setFilteredAppointments] = useState(appointments);
    const [filters, setFilters] = useState({ technician: 'all', status: 'all', search: '' });
    const [modalData, setModalData] = useState(null);
    const [showModal, setShowModal] = useState({ add: false, edit: false, view: false });

    useEffect(() => {
        // Filtros y búsqueda
        const filtered = appointments.filter(appointment => {
            const matchesTechnician = filters.technician === 'all' || appointment.technician === filters.technician;
            const matchesStatus = filters.status === 'all' || appointment.status === filters.status;
            const matchesSearch = appointment.client.toLowerCase().includes(filters.search.toLowerCase());
            return matchesTechnician && matchesStatus && matchesSearch;
        });
        setFilteredAppointments(filtered);
    }, [filters, appointments]);

    const handleFilterChange = (event) => {
        setFilters({ ...filters, [event.target.id]: event.target.value });
    };

    const handleSearchChange = (event) => {
        setFilters({ ...filters, search: event.target.value });
    };

    const openModal = (modalType, data) => {
        setModalData(data);
        setShowModal({ ...showModal, [modalType]: true });
    };

    const closeModal = (modalType) => {
        setShowModal({ ...showModal, [modalType]: false });
        if (modalType === 'add' || modalType === 'edit') {
            setModalData(null);
        }
    };

    const handleFormSubmit = (event, modalType) => {
        event.preventDefault();
        // Lógica para agregar/editar una cita
        // Aquí puedes agregar lógica para guardar los datos en una base de datos o en el estado
        closeModal(modalType);
    };

    return (
        <main>
            <div className="container mt-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h2>Agenda</h2>
                    <button className="btn btn-primary" onClick={() => openModal('add')}>
                        <i className="fas fa-calendar-plus"></i> Nueva Cita
                    </button>
                </div>

                {/* Filtros y Búsqueda */}
                <div className="row mb-4">
                    <div className="col-md-4">
                        <div className="input-group">
                            <label className="input-group-text" htmlFor="filterTechnician">Filtrar por Técnico</label>
                            <select className="form-select" id="filterTechnician" value={filters.technician} onChange={handleFilterChange}>
                                <option value="all">Todos</option>
                                <option value="Tecnico 1">Técnico 1</option>
                                <option value="Tecnico 2">Técnico 2</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="input-group">
                            <label className="input-group-text" htmlFor="filterStatus">Estado</label>
                            <select className="form-select" id="filterStatus" value={filters.status} onChange={handleFilterChange}>
                                <option value="all">Todos</option>
                                <option value="pendiente">Pendiente</option>
                                <option value="confirmado">Confirmado</option>
                                <option value="completado">Completado</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <input type="text" className="form-control" id="searchAppointments" placeholder="Buscar citas..." value={filters.search} onChange={handleSearchChange} />
                    </div>
                </div>

                {/* Lista de Citas */}
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead className="table-dark">
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Cliente</th>
                                <th scope="col">Técnico</th>
                                <th scope="col">Fecha y Hora</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredAppointments.map((appointment) => (
                                <tr key={appointment.id} data-status={appointment.status} data-technician={appointment.technician}>
                                    <td>{appointment.id}</td>
                                    <td>{appointment.client}</td>
                                    <td>{appointment.technician}</td>
                                    <td>{new Date(appointment.date).toLocaleString()}</td>
                                    <td><span className={`badge bg-${appointment.status === 'pendiente' ? 'warning text-dark' : appointment.status === 'confirmado' ? 'success' : 'secondary'}`}>{appointment.status.charAt(0).toUpperCase() + appointment.status.slice(1)}</span></td>
                                    <td>
                                        <div className="dropdown">
                                            <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                Acciones
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                                <li><a className="dropdown-item" href="#" onClick={() => openModal('view', appointment)}>Ver</a></li>
                                                <li><a className="dropdown-item" href="#" onClick={() => openModal('edit', appointment)}>Editar</a></li>
                                                <li><a className="dropdown-item text-danger" href="#" onClick={() => console.log(`Eliminar cita ${appointment.id}`)}>Eliminar</a></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Modal para Ver Cita */}
            {showModal.view && modalData && (
                <div className="modal fade show d-block" tabIndex="-1" aria-labelledby="viewAppointmentModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Detalles de la Cita</h5>
                                <button type="button" className="btn-close" onClick={() => closeModal('view')}></button>
                            </div>
                            <div className="modal-body">
                                <p><strong>Cliente:</strong> {modalData.client}</p>
                                <p><strong>Técnico:</strong> {modalData.technician}</p>
                                <p><strong>Fecha y Hora:</strong> {new Date(modalData.date).toLocaleString()}</p>
                                <p><strong>Estado:</strong> {modalData.status.charAt(0).toUpperCase() + modalData.status.slice(1)}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => closeModal('view')}>Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal para Editar Cita */}
            {showModal.edit && modalData && (
                <div className="modal fade show d-block" tabIndex="-1" aria-labelledby="editAppointmentModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Editar Cita</h5>
                                <button type="button" className="btn-close" onClick={() => closeModal('edit')}></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={(e) => handleFormSubmit(e, 'edit')}>
                                    <input type="hidden" value={modalData.id} />
                                    <div className="mb-3">
                                        <label htmlFor="editAppointmentClient" className="form-label">Cliente</label>
                                        <input type="text" className="form-control" id="editAppointmentClient" defaultValue={modalData.client} required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="editAppointmentTechnician" className="form-label">Técnico</label>
                                        <select className="form-select" id="editAppointmentTechnician" defaultValue={modalData.technician} required>
                                            <option value="">Seleccione un técnico</option>
                                            <option value="Tecnico 1">Técnico 1</option>
                                            <option value="Tecnico 2">Técnico 2</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="editAppointmentDate" className="form-label">Fecha y Hora</label>
                                        <input type="datetime-local" className="form-control" id="editAppointmentDate" defaultValue={new Date(modalData.date).toISOString().slice(0,16)} required />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Guardar Cambios</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal para Nueva Cita */}
            {showModal.add && (
                <div className="modal fade show d-block" tabIndex="-1" aria-labelledby="addAppointmentModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Nueva Cita</h5>
                                <button type="button" className="btn-close" onClick={() => closeModal('add')}></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={(e) => handleFormSubmit(e, 'add')}>
                                    <div className="mb-3">
                                        <label htmlFor="appointmentClient" className="form-label">Cliente</label>
                                        <input type="text" className="form-control" id="appointmentClient" required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="appointmentTechnician" className="form-label">Técnico</label>
                                        <select className="form-select" id="appointmentTechnician" required>
                                            <option value="">Seleccione un técnico</option>
                                            <option value="Tecnico 1">Técnico 1</option>
                                            <option value="Tecnico 2">Técnico 2</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="appointmentDate" className="form-label">Fecha y Hora</label>
                                        <input type="datetime-local" className="form-control" id="appointmentDate" required />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Guardar Cita</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </main>
    );
};

export default Agenda;