import React, { useEffect } from 'react';
import { Link } from 'react-scroll';
import '../css/style_sistema.css';
import {
  imgLogo
} from '../../assets/images/img'; // Ajusta la ruta según sea necesario
import useStickyHeader from '../../components/useStickyHeader'; // Llamada Funcion sticky 

const Header = () => {
  useStickyHeader(); // Funcion sticky

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark bg-gradient-primary">
        <div className="container-fluid">
          <a className="navbar-brand d-flex align-items-center" href="/html/admin/admin_inicio.html">
            <img src={imgLogo} className="logo rounded-circle me-2" alt="Logo FK Soluciones"
              style={{ width: '40px', height: '40px' }} />
            <span style={{ fontweight: 'bold', fontSize: '1.2em' }}>FK Soluciones Automotrices</span>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/Dashboard">Inicio</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Agenda">Agenda</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Inventario">Inventario</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Finanzas">Facturación y Finanzas</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>


    </header>
  );
};

export default Header;