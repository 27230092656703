import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Layouts
import ClientLayout from './layouts/ClientLayout'; // Ajustado para la ubicación correcta
import AdminLayout from './layouts/AdminLayout';   // Ajustado para la ubicación correcta

// Vistas clientes
import Inicio from './pages/cliente/Inicio'; // Ajustado para la ubicación correcta
import Agendar from './pages/cliente/Agendar'; // Ajustado para la ubicación correcta

// Vistas admin
import Dashboard from './pages/admin/Dashboard'; // Ajustado para la ubicación correcta
import Agenda from './pages/admin/Agenda'; // Ajustado para la ubicación correcta
import Inventario from './pages/admin/inventario/Inventario'; // Ajustado para la ubicación correcta


const App = () => {
  return (
    <Router>
      <Routes>
        {/* Ruta para la vista de cliente */}
        <Route path="/" element={<ClientLayout><Inicio /></ClientLayout>} />

        {/* Ruta para la vista de Agendar */}
        <Route path="/Agendar" element={<ClientLayout><Agendar /></ClientLayout>} />

        {/* Ruta para la vista de Dashboard */}
        <Route path="/Dashboard" element={<AdminLayout><Dashboard /></AdminLayout>} />

        {/* Ruta para la vista de Agenda */}
        <Route path="/Agenda" element={<AdminLayout><Agenda /></AdminLayout>} />

        {/* Ruta para la vista de Inventario */}
        <Route path="/Inventario" element={<AdminLayout><Inventario /></AdminLayout>} />

        {/* Ruta por defecto para manejar cualquier ruta no definida */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;