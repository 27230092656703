// src/pages/admin/inventario/components/ProductForms.js
import React from 'react';

const ProductForms = () => (
    <>
        {/* Modal para Añadir Producto */}
        <div className="modal fade" id="addProductModal" tabindex="-1" aria-labelledby="addProductModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addProductModalLabel">Añadir Nuevo Producto</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form id="productForm" novalidate>
                            <div className="mb-3">
                                <label htmlFor="productName" className="form-label">Nombre del Producto</label>
                                <input type="text" className="form-control" id="productName" required />
                                <div className="invalid-feedback">Por favor, ingrese el nombre del producto.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="productCategory" className="form-label">Categoría</label>
                                <select className="form-select" id="productCategory" required>
                                    <option value="">Seleccione una categoría</option>
                                    <option value="Llaves">Llaves</option>
                                    <option value="Alarmas">Alarmas</option>
                                    <option value="Inmovilizadores">Inmovilizadores</option>
                                </select>
                                <div className="invalid-feedback">Por favor, seleccione una categoría.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="productQuantity" className="form-label">Cantidad</label>
                                <input type="number" className="form-control" id="productQuantity" required min="1" />
                                <div className="invalid-feedback">Por favor, ingrese una cantidad válida.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="productMinStock" className="form-label">Stock Mínimo</label>
                                <input type="number" className="form-control" id="productMinStock" required min="1" />
                                <div className="invalid-feedback">Por favor, ingrese un stock mínimo válido.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="productCost" className="form-label">Costo</label>
                                <input type="number" className="form-control" id="productCost" required min="0" />
                                <div className="invalid-feedback">Por favor, ingrese un costo válido.</div>
                            </div>
                            <button type="submit" className="btn btn-primary">Guardar Producto</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        {/* Modal para Editar Producto */}
        <div className="modal fade" id="editProductModal" tabindex="-1" aria-labelledby="editProductModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="editProductModalLabel">Editar Producto</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form id="editProductForm" novalidate>
                            <input type="hidden" id="editProductId" />
                            <div className="mb-3">
                                <label htmlFor="editProductName" className="form-label">Nombre del Producto</label>
                                <input type="text" className="form-control" id="editProductName" required />
                                <div className="invalid-feedback">Por favor, ingrese el nombre del producto.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="editProductCategory" className="form-label">Categoría</label>
                                <select className="form-select" id="editProductCategory" required>
                                    <option value="">Seleccione una categoría</option>
                                    <option value="Llaves">Llaves</option>
                                    <option value="Alarmas">Alarmas</option>
                                    <option value="Inmovilizadores">Inmovilizadores</option>
                                </select>
                                <div className="invalid-feedback">Por favor, seleccione una categoría.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="editProductQuantity" className="form-label">Cantidad</label>
                                <input type="number" className="form-control" id="editProductQuantity" required min="1" />
                                <div className="invalid-feedback">Por favor, ingrese una cantidad válida.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="editProductMinStock" className="form-label">Stock Mínimo</label>
                                <input type="number" className="form-control" id="editProductMinStock" required min="1" />
                                <div className="invalid-feedback">Por favor, ingrese un stock mínimo válido.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="editProductCost" className="form-label">Costo</label>
                                <input type="number" className="form-control" id="editProductCost" required min="0" />
                                <div className="invalid-feedback">Por favor, ingrese un costo válido.</div>
                            </div>
                            <button type="submit" className="btn btn-primary">Guardar Cambios</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default ProductForms;
