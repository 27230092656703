// src/layouts/ClientLayout.js
import React from 'react';
import Header from '../pages/cliente/Header'; // Ajustado para la ubicación correcta
import Footer from '../pages/cliente/Footer'; // Ajustado para la ubicación correcta

const ClientLayout = ({ children }) => (
  <div>
    <Header />
    {children}
    <Footer />
  </div>
);

export default ClientLayout;