import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CategoryForms from './CategoryForms';
import ProductForms from './ProductForm';


const Inventario = () => {
    const [categorias, setCategorias] = useState([]);
    const [repuestos, setRepuestos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const loadCategorias = async () => {
        try {
            const response = await axios.get('http://localhost/fk-soluciones-automotrices/backend/inventario/categoria/categorias.php');
            console.log('Datos recibidos:', response.data); // Verifica la estructura de los datos
            if (Array.isArray(response.data)) {
                setCategorias(response.data);
            } else {
                throw new Error('Datos recibidos no son un array.');
            }
        } catch (error) {
            setError('Error al cargar las categorías: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const loadRepuestos = async () => {
        try {
            const response = await axios.get('http://localhost/fk-soluciones-automotrices/backend/inventario/repuesto/repuestos.php');
            console.log('Datos recibidos:', response.data); // Verifica la estructura de los datos
            if (Array.isArray(response.data)) {
                setRepuestos(response.data);
            } else {
                throw new Error('Datos recibidos no son un array.');
            }
        } catch (error) {
            setError('Error al cargar los repuestos: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadCategorias();
        loadRepuestos();
    }, []);

    const onEdit = (id, nombre) => {
        // Función para manejar la edición de una categoría
        console.log('Editar categoría', id, nombre);
    };

    const onDelete = (id) => {
        // Función para manejar la eliminación de una categoría
        console.log('Eliminar categoría', id);
    };

    const filterProducts = () => {
        // Función para filtrar productos por categoría
        console.log('Filtrar productos por categoría');
    };

    const confirmDelete = (tipo) => {
        // Función para confirmar la eliminación de un producto
        console.log('Confirmar eliminación de', tipo);
    };

    if (isLoading) {
        return <p>Cargando...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <main className="container mt-4">
            {/* Encabezado con botones para agregar categorías y productos */}
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2>Gestión de Inventario</h2>
                <div>
                    <button className="btn btn-secondary me-2" data-bs-toggle="modal" data-bs-target="#addCategoryModal">Añadir Categoría</button>
                    <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addProductModal">Añadir Producto</button>
                </div>
            </div>

            <table className="table table-bordered mb-4" id="categoryTable">
                <thead className="table-light">
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Nombre de la Categoría</th>
                        <th scope="col">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {categorias.length > 0 ? (
                        categorias.map(categoria => (
                            <tr key={categoria.id_cat} data-category={categoria.nombre_cat}>
                                <td>{categoria.id_cat}</td>
                                <td>{categoria.nombre_cat}</td>
                                <td>
                                    <button
                                        className="btn btn-warning btn-sm"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editCategoryModal"
                                        onClick={() => onEdit(categoria.id_cat, categoria.nombre_cat)}
                                    >
                                        Editar
                                    </button>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => onDelete(categoria.id_cat)}
                                    >
                                        Eliminar
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3">No hay categorías disponibles</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Filtro de Productos por Categoría */}
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>Productos</h4>
                <div className="input-group" style={{ width: '300px' }}>
                    <label className="input-group-text" htmlFor="filterCategory">Filtrar por Categoría</label>
                    <select className="form-select" id="filterCategory" onChange={filterProducts}>
                        <option value="all">Todas</option>
                        <option value="Llaves">Llaves</option>
                        <option value="Alarmas">Alarmas</option>
                        <option value="Inmovilizadores">Inmovilizadores</option>
                    </select>
                </div>
            </div>

            {/* Tabla de Inventario */}
            <table className="table table-striped table-hover" id="productTable">
                <thead className="table-dark">
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Producto</th>
                        <th scope="col">Categoría</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Stock Mínimo</th>
                        <th scope="col">Costo</th>
                        <th scope="col">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {repuestos.length > 0 ? (
                        repuestos.map(repuesto => (
                            <tr key={repuesto.id_rep} data-category={repuesto.nombre_rep}>
                                <td>{repuesto.id_rep}</td>
                                <td>{repuesto.nombre_rep}</td>
                                <td>{repuesto.cantidad_rep}</td>
                                <td>{repuesto.costo_rep}</td>
                                <td>{repuesto.id_cat}</td>
                                <td>{repuesto.stock_min_rep}</td>
                                <td>
                                    <button
                                        className="btn btn-warning btn-sm"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editCategoryModal"
                                        onClick={() => onEdit(repuesto.id_rep, repuesto.nombre_rep)}
                                    >
                                        Editar
                                    </button>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => onDelete(repuesto.id_rep)}
                                    >
                                        Eliminar
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3">No hay categorías disponibles</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Importa los formularios de categorías y productos */}
            <CategoryForms />
            <ProductForms />
        </main >
    );
};

export default Inventario;
